<template>
  <div v-if="$can('notic_list', 'notic')">
    <v-alert text dense color="warning" border="left">
      <span class="text-body-2">系统公告用于小程序端通知用户最新的消息，为了小程序页面效果建议最多显示2条</span>
    </v-alert>
    <v-card>
      <v-card-title v-if="$can('notic_save', 'notic')" class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center pb-5">
          <!-- create invoice -->
          <v-btn color="primary" class="me-3" @click="editItem()">
            <v-icon size="18" class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新增</span>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table :footer-props="{
          itemsPerPageAllText: '全部',
          itemsPerPageText: '每页显示 ',
          showCurrentPage: true
        }" disable-filtering :headers="getHeader()" :items="notics" :items-per-page="pages.itemsPerPage"
          :server-items-length="pages.serverItemsLength" no-data-text="无数据" @pagination="loadPage">
          <template #[`item.isShow`]="{ item }">
            <v-switch v-model="item.isShow" dense x-small :label="item.isShow ? `首页显示` : `禁用`"
              @change="toggleEnabled(item)"></v-switch>
          </template>
          <!-- action -->
          <template #[`item.actions`]="{ item }">
            <v-btn v-if="$can('notic_edit', 'notic')" text dense x-small color="primary" @click="editItem(item)">
              编辑
            </v-btn>
            <v-btn v-if="$can('notic_delete', 'notic')" text dense x-small color="primary"
              @click="showDeleteDialog(item)">
              删除
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>

      <v-dialog v-model="deleteDialog" width="300">
        <v-card>
          <v-card-text> 删除<span style="color: red">{{ deleteData.title }}</span>? </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="deleteItem()">
              确认删除
            </v-btn>
            <v-btn color="normal" text @click="deleteDialog = false">
              取消
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-navigation-drawer v-model="editDialog" temporary touchless :right="!$vuetify.rtl"
        :width="$vuetify.breakpoint.smAndUp ? '65%' : '100%'" app>
        <v-card height="100%">
          <div class="drawer-header d-flex align-center">
            <span class="font-weight-semibold text-base text-h6">{{ editedItem.id ? '编辑' : '添加' }}系统公告</span>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="mr-5" :disabled="commitFlag" :loading="commitFlag" @click="handleFormSubmit()">
              保存
            </v-btn>
            <v-btn icon small @click="editDialog = false">
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </div>

          <v-card-text style="background-color: #F4F5FA; height: 90%; padding-top: 20px;" class="overflow-auto">
            <v-card>
              <v-form ref="editForm" lazy-validation @submit.prevent="handleFormSubmit">
                <v-card-text class="pa-3">
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="2">
                        <label for="name"><span style="color: red">*</span> 标题名称：</label>
                      </v-col>
                      <v-col cols="12" md="9">
                        <v-text-field id="title" v-model="editedItem.title" :rules="rules.required" required counter="25"
                          label="公告标题" outlined clearable dense></v-text-field>
                      </v-col>
                      <v-col cols="12" md="2">
                        <label for="sort"><span style="color: red">*</span>公告内容：</label>
                      </v-col>
                      <v-col cols="12" md="9">
                        <ckeditor v-model="editorData" :editor="editor" :config="editorConfig"></ckeditor>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-form>
            </v-card>
          </v-card-text>
        </v-card>
      </v-navigation-drawer>
    </v-card>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
} from '@mdi/js'
import NoticApi from '@/api/market/notic'
import UploadApi from '@/api/upload'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { MyCustomUploadAdapterPlugin } from '@/plugins/upload-adapter'

export default {
  name: 'Notic',
  components: {
  },
  data() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
      },
      editor: ClassicEditor,
      editorData: '',
      editorConfig: {
        // The configuration of the editor.
        language: 'zh-cn',
        toolbar: ['heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', 'uploadImage', 'undo', 'redo'],
        extraPlugins: [MyCustomUploadAdapterPlugin],
      },
      editDialog: false,
      deleteDialog: false,
      rules: {
        required: [v => !!v || '该字段不能为空'],
      },
      loadings: {
        submitLoading: false,
      },
      notics: [],
      pages: {
        page: 1,
        itemsPerPage: 15,
        serverItemsLength: 0,
      },
      editedItem: {},
      editedIndex: -1,
      defaultItem: {
        id: undefined,
        title: '',
        content: '',
        isShow: true,
      },
      deleteData: {},
      commitFlag: false,
    }
  },
  methods: {
    getHeader() {
      const baseHeader = [
        { text: '公告标题', value: 'title' },
        { text: '创建时间', value: 'createdTime' },
        { text: '修改时间', value: 'updatedTime' },
      ]
      if (this.$can('notic_toggleShow', 'notic')) {
        baseHeader.push({ text: '显示', value: 'isShow' })
      }

      if (this.$can('notic_edit', 'notic')
        || this.$can('notic_delete', 'notic')) {
        baseHeader.push({ text: '操作', value: 'actions' })
      }

      return baseHeader
    },
    loadPage({ page, itemsPerPage }) {
      const searchData = {
        rows: itemsPerPage,
        page,
      }
      NoticApi.getPages(searchData)
        .then(response => {
          const { data } = response.data
          this.notics = data.records
          if (data.size === -1) {
            this.pages.serverItemsLength = this.notics.length
          } else {
            this.pages.serverItemsLength = data.total
          }
        })
    },
    editItem(item) {
      if (item) {
        this.editedIndex = this.notics.indexOf(item)
        if (this.$can('notic_edit_init', 'notic')) {
          NoticApi.getNotic(item.id).then(res => {
            const { data } = res.data
            // this.$nextTick(() => {
            //   this.$refs.toastuiEditor.invoke('setMarkdown', data.content, false)
            // })
            const description = data.content || ''
            this.$nextTick(() => {
              this.editorData = description
            })
          })
        }

        this.editedItem = item
      } else {
        this.editedIndex = -1
        this.editedItem = this.defaultItem
        this.editorData = ''
        this.$nextTick(() => {
          this.$refs.editForm.resetValidation()
        })
      }
      this.commitFlag = false
      this.editDialog = true
    },
    handleFormSubmit() {
      const isFormValid = this.$refs.editForm.validate()
      if (!isFormValid) return
      if (this.commitFlag) return

      this.loadings.submitLoading = true
      const submitData = {
        ...this.editedItem,
      }


      submitData.content = this.editorData
      this.commitFlag = true
      NoticApi.saveOrUpdate(submitData)
        .then(response => {
          this.$toast.success('保存成功')
          if (this.editedIndex > -1) {
            Object.assign(this.notics[this.editedIndex], this.editedItem)
          } else {
            this.loadPage(this.pages)
          }
          this.commitFlag = false
          this.editDialog = false
        })
        .finally(() => {
          this.loadings.submitLoading = false
        })
    },
    showDeleteDialog(item) {
      this.deleteData = item
      this.deleteDialog = true
    },
    deleteItem() {
      NoticApi
        .delete(this.deleteData.id)
        .then(() => {
          this.$toast.success('删除成功！')
          this.deleteData = {}
          this.loadPage(this.pages)
          this.deleteDialog = false
        })
    },
    toggleEnabled(item) {
      const saveData = {
        id: item.id,
        isShow: item.isShow,
      }
      NoticApi.toggleShow(saveData).then(res => {
        this.$toast.success('修改成功！')
      })
    },
  },
}
</script>

<style scoped></style>
