import axios from '@axios'

import qs from 'qs'

const prefixPath = '/api/market/notic'
export default {

  saveOrUpdate(data) {
    return axios.post(`${prefixPath}/saveOrUpdate`, data)
  },

  toggleShow(data) {
    return axios.post(`${prefixPath}/toggleShow`, data)
  },

  getPages(data) {
    return axios.post(`${prefixPath}/getPages`, data)
  },

  delete(id) {
    return axios.post(`${prefixPath}/delete`, qs.stringify({
      id,
    }))
  },
  listAll() {
    return axios.get(`${prefixPath}/listAll`)
  },

  getNotic(id) {
    return axios.get(`${prefixPath}/getNotic`, {
      params: {
        id,
      },
    })
  },

}
